<template>
  <div class="product-images" v-if="ready">
    <!-- CONTENT FOR DESKTOP max-width: 768px-->
    <div v-if="desktop" class="product-images__large">
      <div
        class="gallery-vertical"
        :class="photos.length > 5 ? '' : 'gallery-vertical--static'"
      >
        <template v-if="photos.length > 5">
          <VueSlickCarousel v-bind="verticalCarousel" :dots="false">
            <div
              class="gallery-vertical__item"
              v-for="(photo, index) in photos"
              :key="index + product.id"
            >
              <div
                class="gallery-square"
                @click="urlImage(photo.path)"
                style="cursor: pointer"
                v-show="photo.path"
              >
                <img
                  :src="$getImgUrl(photo.path) + 'tr=w-65,h-65'"
                  :alt="product.name"
                  class=""
                />
              </div>
            </div>
          </VueSlickCarousel>
        </template>
        <template v-else>
          <div
            class="gallery-vertical__item"
            v-for="(photo, index) in photos"
            :key="index + product.id"
          >
            <div
              class="gallery-square"
              @click="urlImage(photo.path)"
              style="cursor: pointer"
              v-show="photo.path"
            >
              <img
                :src="$getImgUrl(photo.path) + 'tr=w-65,h-65'"
                :alt="product.name"
                class=""
              />
            </div>
          </div>
        </template>
      </div>

      <!-- <div class="product-images__large__gallery">
          <div
            class="product-images__large__gallery__item"
            v-for="(photo, index) in photos"
            :key="index + product.id"
          >
            <div
              class="gallery-square"
              @click="urlImage(photo.path)"
              style="cursor: pointer"
              v-show="photo.path"
            >
              <img
                :src="$getImgUrl(photo.path) + 'tr=w-65,h-65'"
                :alt="product.name"
                class="product-images__large__gallery__item__img"
              />
            </div>
          </div>
        </div> -->

      <!-- TODO: Images actual -->
      <!--<div class="product-images__large__main">
          <img class="" v-show="url" :src="url" :alt="product.name" />
        </div>-->

      <!-- TODO: Images responsive nou FAQ

          - al img la full 500px
          - al source mq575 la 330px -->

      <!-- TODO: Images responsive nou-->
      <picture class="product-images__large__main">
        <source :srcset="url + `?tr=w-330,h-330`" media="(max-width: 575px)" />
        <img
          :src="url + `?tr=w-500,h-500`"
          v-show="url"
          :alt="product.name"
          class="product-images__large__gallery__item__img"
        />
      </picture>
    </div>

    <!-- CONTENT FOR MOBILE-->
    <div v-else class="product-images__small">
      <product-carousel
        v-if="showCarousel"
        :photos="product.photos"
        :dots="true"
      ></product-carousel>
    </div>
  </div>
</template>

<script>
import ResponsiveUtilities from "../../../Mixins/ResponsiveUtilities";
import { mapGetters } from "vuex";

import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  name: "ProductImages",
  mixins: [ResponsiveUtilities],
  components: {
    VueSlickCarousel,
    ProductCarousel: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "ProductCarousel" */ "./ProductCarousel"
      ),
  },
  props: {
    ready: {
      type: Boolean,
      required: false,
    },
    product: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      url: "",
      verticalCarousel: {
        centerMode: false,
        vertical: true,
        verticalSwiping: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        arrows: true,
        infinite: true,
      },
    };
  },
  watch: {
    product: {
      handler(newValue) {
        if (!newValue) {
          this.url = this.$getImgUrl(this.product.photo);
        }
        this.url = this.$getImgUrl(newValue.photo);
      },
      // force eager callback execution
      immediate: true,
    },
    selectedVariant: {
      handler(newValue) {
        if (!newValue) {
          this.url = this.$getImgUrl(this.product.photo);
        } else this.url = newValue?.imageUrl;
      },
    },
  },
  computed: {
    ...mapGetters({
      selectedVariant: "selectedVariant",
    }),
    photos() {
      //return this.product.photos?.slice(0, 6);
      return this.product.photos;
    },
    showCarousel() {
      try {
        return this.product.photos?.length > 0;
      } catch (err) {
        return false;
      }
    },
  },
  methods: {
    urlImage(url) {
      this.url = this.$getImgUrl(url);
    },
  },
  mounted() {
    this.url = this.$getImgUrl(this.product.photo);
  },
};
</script>
