<template>
  <div>
    <div class="bbbk pad-t-fs-smaller pad-b-fs-smaller bg-grey-6">
      <div class="bb-container">
        <div class="header-search__search-product">
          <div class="bbsk bbsk--input"></div>
        </div>
      </div>
    </div>
    <div class="bb-container">
      <div class="bb-breadcrumb">
        <span class="bbsk bbsk-h6" style="width: 50%"></span>
      </div>
      <div class="g-product">
        <div class="g-product__left">
          <div class="g-product__pictures">
            <div class="product-images">
              <div class="product-images__small">
                <div
                  class="bbsk bbsk--image"
                  style="width: 100%; max-height: 330px"
                ></div>
              </div>
              <div class="product-images__large">
                <div class="product-images__large__gallery">
                  <div class="product-images__large__gallery__item">
                    <div class="bbsk bbsk--image" style="width: 65px"></div>
                  </div>
                  <div class="product-images__large__gallery__item">
                    <div class="bbsk bbsk--image" style="width: 65px"></div>
                  </div>
                  <div class="product-images__large__gallery__item">
                    <div class="bbsk bbsk--image" style="width: 65px"></div>
                  </div>
                </div>
                <div class="product-images__large__main">
                  <div
                    class="bbsk bbsk--image"
                    style="width: 100%; max-height: 500px"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="g-product__summary">
          <div class="bbskcard bbskcard--product-details">
            <div
              class="bbsk bbsk--h6 bbsk--block mar-b-fs-smaller"
              style="width: 20%"
            ></div>
            <div
              class="bbsk bbsk--h3 bbsk--block mar-b-fs-smaller"
              style="width: 80%"
            ></div>
            <div class="bbsk bbsk--h3 bbsk--block mar-b-1" style="width: 70%"></div>

            <div class="bbsk bbsk--h6 bbsk--block mar-b-fs" style="width: 40%"></div>

            <div
              class="bbsk bbsk--h5 bbsk--block mar-b-fs-smaller"
              style="width: 30%"
            ></div>

            <div class="bbsk bbsk--hr mar-b-fs-smaller mar-t-fs"></div>

            <div class="bbsk bbsk--button bbsk--block" style="width: 100%"></div>

            <div class="bbsk bbsk--hr"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductPageSkeleton",
};
</script>

<style lang="scss" scoped></style>
