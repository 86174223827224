<template>
  <div :id="id" ref="button" class="product-add-to-list">
    <product-added-to-list-modal
      :show="showProductAddedToList"
      @cancel="cancelProductAddedToList"
    />

    <product-login-to-access-your-list-modal
      v-if="showLoginModal"
      @cancel="cancelLoginModal"
      @afterLogin="addProductToList"
    />
    <slot>
      <!--      TODO IS PRODUCT SAVED amb e-commerce cal canviar?-->
      <button
        @click="addProduct"
        class="button button--block button--ico"
        :class="highlightAddToListButton ? 'button--primary' : 'button--outline-dark'"
      >
        <i v-if="isProductSaved" class="uil uil-check"></i>
        <i v-else class="uil uil-plus"></i>
        {{ text }}
      </button>
    </slot>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ProductAddedToListModal from "./ProductAddedToListModal";
import "intersection-observer"; //https://github.com/w3c/IntersectionObserver/tree/master/polyfill

export default {
  components: {
    ProductAddedToListModal,
    ProductLoginToAccessYourListModal: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "ProductLoginToAccessYourListModal" */ "./ProductLoginToAccessYourListModal"
      ),
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    id: {
      type: String,
      default: "add_to_list_button",
    },
    withoutObserver: {
      type: Boolean,
      default: false,
    },
    source: {
      type: String,
    },
    isHero: {
      type: Boolean,
    },
  },
  data() {
    return {
      showProductAddedToList: false,
      showLoginModal: false,
    };
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "isAuthenticated",
      myList: "ownerList",
      selectedVariant: "selectedVariant",
      user: "user",
    }),
    isProductSaved() {
      if (this.isAuthenticated) {
        return this.$store.state.savedProducts.find(
          (savedProducts) => savedProducts === this.product.listProductId
        );
      } else {
        return false;
      }
    },
    text() {
      return this.isProductSaved
        ? "Ya lo tienes en tu lista"
        : "Añadir a tu lista de nacimiento";
    },
    productRetailersName() {
      return this.product.productRetailers?.map(function (item) {
        return item["name"];
      });
    },
    SoldByHelloBB() {
      if (this.productRetailersName?.includes("HelloBB")) {
        return "Active";
      }
      return "No";
    },
    highlightAddToListButton() {
      if (this.SoldByHelloBB === "Active") return false;
      return !!this.isAuthenticated;
    },
  },
  watch: {
    showProductAddedToList(show) {
      this.controlScrollLock(show);
    },
    showLoginModal(show) {
      this.controlScrollLock(show);
    },
    product: "addToList",
  },
  methods: {
    async addToList() {
      if (this.$route.query.src === "blog" && this.isAuthenticated) {
        await this.$store.dispatch("setList");
        await this.addProduct();
        this.showProductAddedToList = true;
      } else if (this.$route.query.src === "blog" && !this.isAuthenticated) {
        this.showLoginModal = true;
      }
    },
    controlScrollLock(show) {
      if (show) {
        if (document) {
          document.body.style.setProperty("overflow", "hidden");
        }
      } else {
        if (document) {
          document.body.style.removeProperty("overflow");
        }
      }
    },
    cancelProductAddedToList() {
      this.showProductAddedToList = false;
    },
    cancelLoginModal() {
      this.showLoginModal = false;
    },
    async addProductToList() {
      console.log("addProductToList", this.product, this.selectedVariant);
      let body = {};
      if (this.product.shopifyId && this.selectedVariant.shopifyId) {
        body = {
          shopifyProductId: this.product.shopifyId,
          shopifyProductVariantId: this.selectedVariant.shopifyId,
        };
      }
      var res = await this.$store.dispatch("addProductToList", {
        list: this.myList,
        product: this.product,
        variantId: this.selectedVariant?.id,
        body,
      });
      this.$store.commit("toggleSaveProduct", this.product);
      this.showProductAddedToList = true;
    },
    async addProduct() {
      if (!this.isProductSaved) {
        if (this.isAuthenticated) {
          try {
            // TODO SHOW LOADING
            // this.loading = true
            await this.addProductToList();
            // this.loading = false
          } catch {
            return;
          }

          let sourceToTrack = "Catàleg";
          if (this.source === "Constructor") sourceToTrack = "Constructor - Fitxa"; // Era per rastrejar origen constructor i de moment no ens fa falta. Borrar en algun moment 15/6/2022
          if (this.source === "Blog") sourceToTrack = "Blog";
          window.mixpanel.people.set_once({
            "First Add": new Date().toISOString(),
          });
          window.mixpanel.track("Afegir objecte", {
            "Num Retailers": this.product.productRetailers.length,
            "Item retailer": this.product.productRetailers[0].name,
            "Item retailers": this.productRetailersName,
            "Sold by HelloBB": this.SoldByHelloBB,
            "Item Category": this.product.mainCategory + ">" + this.product.subCategory1,
            "Item name": this.product.name,
            //"Item Price": this.product.price,
            "Add Platform": this.$getPlatform(),
            "Add client":
              this.$store.state.cookies.sourceApp == "true" ? "App" : "Webapp",
            Source: sourceToTrack,
            "Add type": "Objecte catàleg",
            "Item URL": "https://www.hellobb.net/p/" + this.product.id,
            "Product ID": this.product.id,
            "Destacat?": this.isHero ? "Si" : "No",
          });
          //alert(state.user.id);
          window.mixpanel.people.set({ "Last Add": new Date().toISOString() });
          window.mixpanel.people.increment("Total Add");
          window.mixpanel.people.increment("List # elements");
          window.mixpanel.people.increment("List value", this.product.minPrice);

          this.showProductAddedToList = true;
        } else {
          this.showLoginModal = true;
        }
      }
    },
  },
  mounted() {
    if (!this.withoutObserver) {
      const observer = new IntersectionObserver((entries) => {
        entries.map((entry) => {
          if (entry.isIntersecting || window.scrollY === 0) {
            this.$store.commit("SET_ADD_TO_LIST_VISIBILITY", true);
          } else {
            this.$store.commit("SET_ADD_TO_LIST_VISIBILITY", false);
          }
        });
      });
      let options = {
        // root: document.querySelector('#scrollArea'),
        root: null, // Null = Viewport del navegador
        // rootMargin: '0px',
        threshold: 0,
      };
      observer.observe(this.$refs.button, options);
    }
  },
};
</script>
