<template>
  <div>
    <div class="product-description">
      <div class="product-description__blocks">
        <div
          class="product-description__block"
          v-show="trimmedManufacturerDescription !== ''"
        >
          <div
            class="product-description__header"
            @click="description = !description"
            :class="description ? 'product-description__header--open' : ''"
          >
            <h4
              class="product-description__head"
              :class="description ? 'product-description__head--active' : ''"
            >
              Descripción
            </h4>
            <div class="product-description__right">
              <img
                src="../../../assets/img/ico/ico-chevron-up.svg"
                loading="lazy"
                alt=""
                :class="
                  description
                    ? 'product-description__chevron--active'
                    : 'product-description__chevron'
                "
              />
            </div>
          </div>
          <transition name="bbUp">
            <div
              class="product-description__content"
              v-html="manufacturerDescription"
              v-show="description"
            ></div>
          </transition>
        </div>

        <div
          class="product-description__block"
          v-show="trimmedTechnicalDescription !== ''"
        >
          <div
            class="product-description__header"
            @click="specifications = !specifications"
            :class="specifications ? 'product-description__header--open' : ''"
          >
            <h4
              class="product-description__head"
              :class="specifications ? 'product-description__head--active' : ''"
            >
              Especificaciones
            </h4>
            <div class="product-description__right">
              <img
                src="../../../assets/img/ico/ico-chevron-up.svg"
                loading="lazy"
                alt=""
                :class="
                  specifications
                    ? 'product-description__chevron--active'
                    : 'product-description__chevron'
                "
              />
            </div>
          </div>
          <transition name="bbUp">
            <div
              class="product-description__content"
              v-html="tecnicalDescription"
              v-show="specifications"
            ></div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductDescription",
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      description: false,
      specifications: false,
    };
  },
  computed: {
    trimmedManufacturerDescription() {
      return this.product.manufacturerDescription
        ? this.product.manufacturerDescription.trim()
        : "";
    },
    trimmedTechnicalDescription() {
      return this.product.technicalDescription
        ? this.product.technicalDescription.trim()
        : "";
    },
    manufacturerDescription() {
      if (!this.product.manufacturerDescription) return "";
      return this.product.manufacturerDescription.replace(/(?:\r\n|\r|\n)/g, "<br />");
    },
    tecnicalDescription() {
      if (!this.product.technicalDescription) return "";
      return this.product.technicalDescription.replace(/(?:\r\n|\r|\n)/g, "<br />");
    },
  },
};
</script>
