<template>
  <div class="product-abstract">
    <div
      class="product-abstract__item"
    >
      <div class="mas-gusta" v-if="product.whatLikeMost !== null && product.whatLikeMost !== ''">
        <h4 class="mas-gusta__title">
          <img src="../../../assets/img/logos/favicon.svg" alt class="" />
          <span>Lo que más nos gusta</span>
        </h4>
        <div class="mas-gusta__content" v-html="product.whatLikeMost"></div>
      </div>
    </div>

    <div class="product-abstract__item" v-if="product.summary !== null && product.summary !== ''">
      <h3 class="product-abstract__head">Resumen</h3>
      <div class="product-abstract__content" v-html="product.summary"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductAbstract",
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
};
</script>
